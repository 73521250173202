import React from "react";

import { ModuleWrapper } from "../moduleWrapper";
import { CardSlider } from "@components/carousels/cardSlider";
import { MinorCardCarousel } from "@components/carousels/minorCardCarousel";
import { PromoCard } from "@components/cards/promoCard";

const PromoGrid = ({ config, featuredItems, minorItems, cardCarousel }) => {
  return (
    <ModuleWrapper {...config}>
      <div className="grid grid-cols-6 gap-6 ">
        <div className="col-span-6 lg:col-span-4 px-nav-gutter lg:pr-0">
          <CardSlider
            items={featuredItems}
            aspectRatio="square"
            isPromo
            isAboveFold
          />
        </div>
        <div className="col-span-6 grid gap-6 md:grid-cols-2 lg:grid-cols-1 lg:col-span-2 px-nav-gutter lg:pl-0">
          {minorItems.map((item, index) => (
            <PromoCard {...item} key={index} />
          ))}
        </div>
        <div className="col-span-6 row-start-2 md:row-start-3 md:pt-20">
          <MinorCardCarousel {...cardCarousel} />
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default PromoGrid;
